/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap');
@import './assets/theme/overrides';


html,
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

@media (max-width: 991.98px) {
  body {
    overflow-x: hidden;
    padding-bottom: 0;
  }
}
