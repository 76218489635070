@import './global';

.search-ui-dialog .mat-dialog-container {
  background: transparent;
}

.mat-menu-panel {
  margin-top: 7px;
  .mat-menu-content {
    padding-top: 0;
    padding-bottom: 0;
    background: transparent;
  }
  .mat-menu-item,
  .menu-height,
  .btn-without-submenu {
    height: 35px;
    min-height: 35px;
    line-height: 35px;
    color: $warn-500;
    font-size: 14px;
  }
}

.mat-sidenav-content {
  width: auto;
}

.customer-select {
  .action-bar {
    button {
      background-color: #e37339;
    }

    button:last-child {
      color: #006dda;
      background-color: #ebf3ff;
      border: 1px solid rgba(16, 76, 163, 0.4);
    }

    button:disabled {
      background-color: rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.26);
    }
  }
}

.card {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background-color: #fcfcfc;
}


.modal-content {
  border: 0;
}