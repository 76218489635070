@import "color-variable.scss";

@mixin font-condensed {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
}

@mixin font-roboto {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

@mixin base-button($background-color: $primary-400, $color: white) {
  padding: 10px 20px;
  border: none;
  outline: none;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: $background-color;
  color: $color;
}

.time-input {
  @include font-condensed();

  display: inline-flex;
  align-items: center;
  padding-left: 5px;
  background-color: $warn-800;
  border: 1px solid $primary-500;
  width: 85px;
  height: 35px;
  text-align: start;
  font-size: 18px;
  color: $primary-600;
  font-weight: 700;
}

.primary-button {
  @include base-button();

  &:hover {
    background-color: $primary-500;
  }

  &.disabled {
    background-color: $warn-200;
    color: $warn-400;

    &:hover {
      background-color: $warn-200;
      color: $warn-400;
    }
  }
}

.secondary-button {
  @include base-button($background-color: $primary-300);

  &:hover {
    background-color: $primary-200;
  }

  &.disabled {
    background-color: $warn-200;
    color: $warn-400;

    &:hover {
      background-color: $warn-200;
      color: $warn-400;
    }
  }
}
