// @import 'color-variable.scss'; import this in your scss file to use variables.

$snapp-background-gradient: linear-gradient(#0662bf, #021e3b); // Background Gradient
$dark-primary-text:#27251f;
$light-primary-text:#fefefe;

$primary-50: #000000; // Black and  NOT USED ANY where
$primary-100:#1c9bea;
$primary-200:#0080ff;
$primary-300:#006fdf; // color for all  clickable Links and Icons
$primary-400:#003366; // Delta pallete color
$primary-500:#001c4a;
$primary-600:#051033;
$primary-700:#6d5f7e;  // Delta Purple - Light
$primary-800:#2e1a47;  // Delta Purple - Dark
$primary-900:#5b829c; // #5B829C Diamond Medallion
$primary-A50: #4D6D9E;  // Context Menu Background
$primary-A100:#36333e; // Platinum Medallion
$primary-A200:#8a6e4b; // Gold Medallion
$primary-A400:#8d9090; // Silver Medallion
$primary-A700:#ef5196; //BCRF Light #ff00dd


$accent-100:#f6bac2;
$accent-200:#f08c99;
$accent-300:#df0404; // Alert red for text and bubbles
$accent-400:#e01933; // Delta pallete color
$accent-450:#e51937; // Delta bubble color
$accent-500:#c01933;
$accent-600:#991933;
$accent-700:#ff5600; // Primary selection Button color - Delta Orange
$accent-800:#ff6900; // Light Orange
$accent-900:#ff3b00;// Primary Button color - Dark Orange
$accent-A100:#eaaa00; // - light Yellow
$accent-A200:#e49900; // - Dark Yellow
$accent-A300:#f5d580;
$accent-A350:#f9e6b3;
$accent-A400:#018516; // Success Color - Light Green
$accent-A500:#FF964D;

$warn-50: #ebebeb; //container background
$warn-100:#ffffff; // White
$warn-200:#d0d0ce; // dividers
$warn-300:#a7a8aa;   // for Dissable and no Data
$warn-400:#88888d;  // text titles
$warn-500:#63666a;  // Default Text
$warn-600:#27251f;  // flight nessage text color
$warn-700:#7d9bc1;  //
$warn-800:#d8e6fc;  // for table header and alternate column
$warn-900:#d9ffd9; // row selection color in table (light green)
$warn-A100:#e9ffd9; // Success Message Toaster
$warn-A200:#ffecec; // Alert message Toaster #F6BAC2 (light pink)
$warn-A300:#f1f1f0;  // Itinerary screen
$warn-A400:#fff8c4; // Warning message Toaster # F9E6B3
$warn-A700:#e3f7fc;  // Information Message Toaster (#A4B9D4 this is the right color )

// Snapp Colors
$snapp-background-gradient: linear-gradient(#0662bf, #021e3b); // leave in
$black-half-opacity: rgba(0, 0, 0, 0.50); // leave in
$white-half-opacity: rgba(255, 255, 255, 0.50);  //leave in
$black-quarter-opacity: rgba(0, 0, 0, 0.25); // leave in
$white-quarter-opacity: rgba(255, 255, 255, 0.25); // leave in
$black-text: rgba(0, 0, 0, 0.87); // leave in
$white-text: rgba(255, 255, 255, 0.87); // leave in
$light-divider: rgba(255,255,255,0.12); // leave in
$auto-advance-ticker-gradient-basic: linear-gradient(#3a4869, #5e75b1);
$auto-advance-ticker-gradient-main: linear-gradient(#4e67d5, #1b3c77);
$auto-advance-ticker-gradient-comfort-plus: linear-gradient(#154783, #0879cf);
$auto-advance-ticker-gradient-first: linear-gradient(#d9105b, #821d4a);
$auto-advance-ticker-gradient-delta-one: linear-gradient(#3c0f6a, #ac17bf);
